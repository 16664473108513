<template>
  <b-card no-body>
    <b-card-body>
      <b-row class="filter">
        <b-col cols="12" md="2" class="mb-0">
          <label>{{ $t("Name") }}</label>
          <b-form-input
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="filter.name"
            class="w-100"
          />
        </b-col>
        <b-col cols="12" md="2" class="mb-0">
          <label>{{ $t("Code") }}</label>
          <b-form-input
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="filter.code"
            class="w-100"
          />
        </b-col>
        <b-col cols="12" md="2" class="mb-0">
          <label>{{ $t("Type") }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="typeOptions"
            v-model="filter.type"
            label="name"
            class="w-100"
            :reduce="(val) => val.id"
          />
        </b-col>
        <b-col cols="12" md="2" class="mb-0">
          <label>{{ $t("Status") }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="filter.status"
            :options="statusOptions"
            class="w-100"
            :reduce="(val) => val.value"
          />  
        </b-col>
        <!-- action -->
        <b-col cols="12" md="3" class="d-flex align-items-end justify-content-start">
          <b-button variant="secondary" :disabled="disable" @click="resetFilter()">
            {{ $t("Reset") }}
          </b-button>
          <b-button variant="primary" :disabled="disable" class="ml-2" @click="searchFilter()">
            {{ $t("Search") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BCardText,
  BFormGroup,
  BFormInput,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";
export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardBody,
    BCardText,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    vSelect,
  },
  props: {
    nameFilter: {
      type: [String, null],
      default: null,
    },
    codeFilter: {
      type: [String, null],
      default: null,
    },
    typeFilter: {
      type: [Number, null],
      default: null,
    },
    typeOptions: {
      type: Array,
      default: null,
    },
    statusFilter: {
      type: [Number, null],
      default: null,
    },
    statusOptions: {
      type: Array,
      default: null,
    },
    disable: {
      type: [Boolean, null],
      default: null,
    },
  },
  data() {
    return {
      filter: {
        name: this.nameFilter,
        code: this.codeFilter,
        status: this.statusFilter,
        type: this.typeFilter,
      },
    };
  },
  methods: {
    searchFilter() {
      this.$emit("update:nameFilter", this.filter.name);
      this.$emit("update:statusFilter", this.filter.status);
      this.$emit("update:codeFilter", this.filter.code);
      this.$emit("update:typeFilter", this.filter.type);
      this.$emit("refetch-data");
    },
    resetFilter() {
      this.filter = {
        name: null,
        code: null,
        status: null,
        type: null,
      };
      this.$emit("update:nameFilter", null);
      this.$emit("update:codeFilter", null);
      this.$emit("update:statusFilter", null);
      this.$emit("update:typeFilter", null);
      this.$emit("refetch-data");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
