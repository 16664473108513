<template>
  <div>
    <game-type-filters
      :name-filter.sync="nameFilter"
      :code-filter.sync="codeFilter"
      :type-filter.sync="typeFilter"
      :type-options="typeOptions"
      :disable="loading"
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
      @refetch-data="refetchData"
    />
    <b-card no-body>
      <!--            <div class="p-1">-->
      <!--				<b-row>-->
      <!--					<b-col-->
      <!--						v-if="$can('view','frontendmanagement')"-->
      <!--						class="col d-flex justify-content-end"-->
      <!--					>-->
      <!--						<b-button-->
      <!--							variant="primary"-->
      <!--							@click="addGameType"-->
      <!--						>-->
      <!--							<feather-icon icon="PlusIcon" size="16"/> {{ $t('New') }}-->
      <!--						</b-button>-->
      <!--					</b-col>-->
      <!--				</b-row>-->

      <!--			</div>-->
      <b-table
        ref="refAgentGameTypeTable"
        class="position-relative table-white-space mb-0"
        :items="fetchGameTypes"
        responsive
        :fields="tableColumns"
        primary-key="index"
        show-empty
        empty-text="No matching records found"
        busy.sync="true"
      >
        <div slot="table-busy" class="text-center mt-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>
        <template #cell(name)="data">
          <b-link
            class="cursor-pointer text-white"
            @click="editAgentGameType(data.item)"
          >
            {{ data.item.name }}
          </b-link>
        </template>

        <!-- <template #cell(title)="data">
          <b-link
            class="cursor-pointer text-white"
            @click="editAgentGameType(data.item)"
          >
            {{ data.item.title }}
          </b-link>
        </template> -->

        <template #cell(game_type_id)="data">
          {{ data.item.balance_type.id }}
        </template>
        <template #cell(type)="data">
          {{ data.item.balance_type.name }}
        </template>

        <template #cell(status)="data">
          <div :class="`text-capitalize align-items-center d-flex`">
            <span
              :class="`dot bg-${resolveStatus(data.item.status).variant}`"
            />{{ resolveStatus(data.item.status).label }}
          </div>
        </template>

        <template #cell(image)="data">
          <b-link
            :href="baseUrl + data.item.img"
            target="_blank"
            class="cursor-pointer"
          >
            <b-img
              :src="`${data.item.img ? baseUrl + data.item.img : ''}`"
              fluid
              style="max-width: 200px"
              class="mr-2 rounded"
            />
          </b-link>
        </template>

        <template #cell(action)="data">
          <b-button
          v-if="$can('update','game')"
            v-b-tooltip.hover.v-info
            :title="$t('Edit')"
            variant="primary"
            class="btn-icon btn-sm mr-50"
            @click="editAgentGameType(data.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
        </template>
      </b-table>
      <div class="p-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalGameType"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- Add Game Type Modal -->
    <add-game-type-modal @refetch-data="refetchData" />

    <update-game-type-modal
      :game-type-detail.sync="gameTypeDetail"
      @refetch-data="refetchData"
      :typeOptions="typeOptions"
    />
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BAlert,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BTr,
  BTh,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  VBTooltip,
  BImg,
  BSpinner
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref, watch, onUnmounted, computed } from "@vue/composition-api";
import { formatDateTime } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import store from "@/store";
import baseUrl from "@/libs/base-url";
import agentStoreModule from "@/views/agent/agentStoreModule";
import GameTypeFilters from "./GameTypeFilters.vue";

export default {
  components: {
    GameTypeFilters,
    UpdateGameTypeModal: () => import("./UpdateGameTypeModal"),
    AddGameTypeModal: () => import("./AddGameTypeModal.vue"),
    BButton,
    BFormGroup,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BAlert,
    BImg,
    BSpinner
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      gameTypeDetail: null,
    };
  },
  methods: {
    editAgentGameType(datas) {
      this.gameTypeDetail = datas;
      this.$bvModal.show("modal-update-game-type");
    },
    addGameType() {
      this.$bvModal.show("modal-add-game-type");
    },
  },
  setup() {
    const AGENT_MODULE_NAME = "agent";
    if (!store.hasModule(AGENT_MODULE_NAME))
      store.registerModule(AGENT_MODULE_NAME, agentStoreModule);
    onUnmounted(() => {
      if (store.hasModule(AGENT_MODULE_NAME))
        store.unregisterModule(AGENT_MODULE_NAME);
    });
    const perPage = ref(25);
    const totalGameType = ref(0);
    const currentPage = ref(1);
    const nameFilter = ref(null);
    const codeFilter = ref(null);
    const typeFilter = ref(null);
    const typeOptions = ref([]);
    const statusFilter = ref(null);
    const refAgentGameTypeTable = ref(null);
    const loading = ref(false);
    const tableColumns = [
      { key: "index", label: "#" },
      { key: "name", label: "Name" },
      { key: "code", label: "Code" },
      { key: "image", label: "Image" },
      { key: "player_number", label: "Player number" },
      { key: "order", label: "Order" },
      { key: "game_type_id", label: "Game Type Id" },
      { key: "type", label: "Type" },
      { key: "status", label: "Status" },
      { key: "action", label: "Action" },
    ];

    const refetchData = () => {
      refAgentGameTypeTable.value.refresh();
    };

    watch(
      [currentPage, perPage],
      () => {
        refetchData();
      }
    );

    store.dispatch("agent/fetchGameTypeFilters").then((response) => {
      typeOptions.value = response.data.data.map((e) => ({
        id: e.balance_type.id,
        name: e.balance_type.wallet_name,
      }));
    });

    const fetchGameTypes = (ctx, callback) => {
      loading.value = true;
      store
        .dispatch("agent/fetchGameTypes", {
          page: currentPage.value,
          name: nameFilter.value || null,
          code: codeFilter.value || null,
          status: statusFilter.value,
          type: typeFilter.value
        })
        .then((response) => {
          if (response.data.code == "00") {
            callback(response.data.data);
            totalGameType.value = response.data.count;
            perPage.value = 25; //currentPage.value == 1 ? response.data.data.length : 25
          }
          loading.value = false;
        });
    };
    const dataMeta = computed(() => {
      const localItemsCount = refAgentGameTypeTable.value
        ? refAgentGameTypeTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalGameType.value,
      };
    });

    const statusOptions = [
      { label: "Active", value: 1 },
      { label: "Inactive", value: 2 },
    ];

    const resolveStatus = (status) => {
      if (status == 1) return { label: "Active", variant: "primary" };
      if (status == 2) return { label: "Inactive", variant: "danger" };
      return { label: "-", variant: "secondary" };
    };

    return {
      fetchGameTypes,
      tableColumns,
      perPage,
      currentPage,
      totalGameType,
      dataMeta,
      refAgentGameTypeTable,
      refetchData,
      baseUrl,

      nameFilter,
      codeFilter,
      typeFilter,
      typeOptions,
      statusFilter,
      statusOptions,
      formatDateTime,
      resolveStatus,
      loading
    };
  },
};
</script>